var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.currentUser.superAdmin)?_c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.recommendedByList,"options":_vm.pagination,"server-items-length":_vm.totalRecommendedByList,"loading":_vm.loading,"footer-props":{
      'showFirstLastPage':true,
      'items-per-page-options': [10, 20, 50, 100]
    }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.allCities",fn:function(ref){
    var item = ref.item;
return [_c('v-switch',{attrs:{"disabled":""},model:{value:(item.allCities),callback:function ($$v) {_vm.$set(item, "allCities", $$v)},expression:"item.allCities"}})]}},{key:"item.clear",fn:function(ref){
    var item = ref.item;
return [(_vm.currentUser.superAdmin || (!_vm.currentUser.superAdmin && !item.allCities))?_c('v-avatar',{attrs:{"color":"teal","size":"26","rounded":""}},[_c('router-link',{attrs:{"to":{
            name: 'RecommendedByEdit',
            params: { recommendedById: item.id }
          }}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-pencil")])],1)],1):_vm._e()]}},{key:"item.add",fn:function(ref){
          var item = ref.item;
return [(_vm.currentUser.superAdmin || (!_vm.currentUser.superAdmin && !item.allCities))?_c('v-avatar',{attrs:{"color":"red darken-4","size":"26","rounded":""}},[_c('v-icon',{attrs:{"dark":""},on:{"click":function($event){return _vm.delRecord(item)}}},[_vm._v("mdi-delete")])],1):_vm._e()]}},{key:"body.prepend",fn:function(){return [_c('tr',[_c('td',[_c('v-spacer')],1),_c('td',[_c('v-spacer')],1),_c('td',[_c('v-spacer')],1),_c('td',[_c('v-spacer')],1),_c('td',[_c('v-spacer')],1),_c('td',[_c('v-spacer')],1),_c('td',[_c('v-spacer')],1),_c('td',[_c('v-spacer')],1),_c('td',[_c('v-avatar',{attrs:{"color":"indigo","size":"30"}},[_c('v-icon',{attrs:{"dark":""},on:{"click":function($event){_vm.page = 1;
                _vm.retrieveRecommendedByList();}}},[_vm._v("mdi-magnify ")])],1)],1),_c('td',[_c('router-link',{attrs:{"to":{ name: 'RecommendedByCreate' }}},[_c('v-avatar',{attrs:{"color":"teal","size":"26","rounded":""}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-plus")])],1)],1)],1)])]},proxy:true}],null,false,3527122652)}),_c('ConfirmDialog',{ref:"confirm"})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }